function IconGithub() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0C5.37321 0 0 5.50743 0 12.306C0 17.7438 3.43929 22.3512 8.20714 23.9798C8.80714 24.0923 9.02679 23.712 9.02679 23.3852C9.02679 23.0905 9.01607 22.319 9.01071 21.2904C5.67321 22.0351 4.96607 19.6403 4.96607 19.6403C4.41964 18.2206 3.63214 17.8402 3.63214 17.8402C2.54464 17.0741 3.7125 17.0902 3.7125 17.0902C4.91786 17.1759 5.55 18.3599 5.55 18.3599C6.62143 20.2404 8.35714 19.6993 9.04286 19.3832C9.15 18.5903 9.46071 18.0438 9.80357 17.7384C7.14107 17.4277 4.33929 16.3723 4.33929 11.6578C4.33929 10.313 4.80536 9.21477 5.57143 8.35758C5.44821 8.04685 5.03571 6.79322 5.68929 5.10027C5.68929 5.10027 6.69643 4.76811 8.98929 6.35926C9.94821 6.08604 10.9714 5.9521 11.9946 5.94674C13.0125 5.9521 14.0411 6.08604 15 6.35926C17.2929 4.76811 18.2946 5.10027 18.2946 5.10027C18.9482 6.79322 18.5357 8.04685 18.4125 8.35758C19.1786 9.22013 19.6446 10.3184 19.6446 11.6578C19.6446 16.383 16.8375 17.4223 14.1643 17.7277C14.5929 18.1081 14.9786 18.8581 14.9786 20.0046C14.9786 21.6494 14.9625 22.978 14.9625 23.3798C14.9625 23.7066 15.1768 24.0923 15.7875 23.9691C20.5661 22.3458 24 17.7384 24 12.306C24 5.50743 18.6268 0 12 0Z" fill="white"/>
    </svg>
  )
}

function IconInstagram(props) {
  return (
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <defs>
      <radialGradient cx="-578.95" cy="-837.6" gradientTransform="translate(499.5 629.5) scale(0.75)" gradientUnits="userSpaceOnUse" id="radial-gradient" r="197.06">
        <stop offset="0" stopColor="#f9ed32"/>
        <stop offset="0.36" stopColor="#ee2a7b"/>
        <stop offset="0.44" stopColor="#d22a8a"/>
        <stop offset="0.6" stopColor="#8b2ab2"/>
        <stop offset="0.83" stopColor="#1b2af0"/>
        <stop offset="0.88" stopColor="#002aff"/>
      </radialGradient>
    </defs>
    <g data-name="3-instagram" id="_3-instagram">
      <rect fill="url(#radial-gradient)" height="64" rx="11.2" ry="11.2" transform="translate(64 64) rotate(180)" width="64"/>
      <path fill="#fff" d="M44,56H20A12,12,0,0,1,8,44V20A12,12,0,0,1,20,8H44A12,12,0,0,1,56,20V44A12,12,0,0,1,44,56ZM20,12.8A7.21,7.21,0,0,0,12.8,20V44A7.21,7.21,0,0,0,20,51.2H44A7.21,7.21,0,0,0,51.2,44V20A7.21,7.21,0,0,0,44,12.8Z"/>
      <path fill="#fff" d="M32,45.6A13.6,13.6,0,1,1,45.6,32,13.61,13.61,0,0,1,32,45.6Zm0-22.4A8.8,8.8,0,1,0,40.8,32,8.81,8.81,0,0,0,32,23.2Z"/>
      <circle fill="#fff" cx="45.6" cy="19.2" r="2.4"/>
    </g>
  </svg>
  )
}

export {IconGithub, IconInstagram}